import { Component, OnInit } from '@angular/core';

export interface NavTabItem {
  path: string;
  title: string;
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public links: NavTabItem[] = [
    { path: 'sign-in', title: 'Sign In' },
    { path: 'sign-up', title: 'Sign Up' },
  ];
  public activeLink: string = this.links[0].path;

  constructor() {}

  ngOnInit(): void {}
}
