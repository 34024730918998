import { Component, Inject, OnInit } from '@angular/core';
import { TestTakerService } from '../services/test-taker.service';
import { ITestTaker } from '../interfaces/test-taker.interface';
import { API_PAGE_LIMIT } from '../constants/api.constant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, map, mergeMap, of, startWith } from 'rxjs';
import { TrieService } from '../services/trie.service';

@Component({
  selector: 'app-search-test-takers-dailog',
  templateUrl: './search-test-takers-dailog.component.html',
  styleUrls: ['./search-test-takers-dailog.component.scss'],
})
export class SearchTestTakersDailogComponent implements OnInit {
  public _testTakers: ITestTaker[] = [];
  public callInProgress: boolean = false;
  public limit: number = 1000;
  public searchControl: FormControl<any> = new FormControl(null);
  public testTakerIndexMap: { [key in string]: any } = {};
  public testTakers$: Observable<ITestTaker[]> = new Observable();
  public get testTakers() {
    return this._testTakers;
  }
  public set testTakers(value: ITestTaker[]) {
    this._testTakers = value;
    this.testTakers$ = of(value);
    this.convertListToIndexMap('name', value, this.testTakerIndexMap);
    this.convertListToIndexMap('phoneNumber', value, this.testTakerIndexMap);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { sessionId: string, unitCode: string },
    private readonly testTakerService: TestTakerService,
    private readonly snackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<SearchTestTakersDailogComponent>,
    private readonly trieService: TrieService
  ) {}

  ngOnInit(): void {
    this.findTestTakers();

    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        mergeMap((value) => {
          // INFO EX. NULL
          if (typeof value !== 'string') {
            return [];
          }

          const name$ = of(
            this.prefixSearch(
              value,
              this.testTakers.map((el) => el.name)
            )
          ).pipe(
            map((result) =>
              result.map((str) => this.testTakers[this.testTakerIndexMap[str]])
            )
          );

          const phoneNumber$ = of(
            this.prefixSearch<string>(
              value,
              this.testTakers.map((el) => el.phoneNumber)
            )
          ).pipe(
            map((result) =>
              result.map((str) => this.testTakers[this.testTakerIndexMap[str]])
            )
          );

          return combineLatest([name$, phoneNumber$]).pipe(
            map((data) => [
              ...new Set(data.reduce((prev, cur) => [...prev, ...cur], [])),
            ])
          );
        })
      )
      .subscribe({
        next: (value) => {
          if (!value) return;

          this.testTakers$ = of(value.length === 0 ? this.testTakers : value);
        },
      });
  }

  public prefixSearch<T = string>(value: string, wordList: any[]) {
    return this.trieService.prefixSearchVariant<T>(value, wordList);
  }

  private convertListToIndexMap<T = any>(
    field: string,
    list: T[],
    map: { [key in string]: number }
  ) {
    list.forEach((el, i) => (map[(el as any)[field]] = i));
  }

  public selectTestTaker(index: number) {
    this.dialogRef.close(this.testTakers[index]);
  }

  public findTestTakers() {
    this.callInProgress = true;

    const limit = this.limit;
    const offset = this.testTakers.length;

    this.testTakerService
      .getTestTakers(this.data.sessionId, this.data.unitCode, limit, offset)
      .subscribe({
        next: (results) => {
          this.testTakers = [...this.testTakers, ...results];
          this.snackBar.open('Successfully retrieved Test Takers!', 'close', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['bg-primary'],
          });
        },
        error: (error) => {
          console.error(`[getTestTakers]`, error);
          this.snackBar.open(
            'Apologies, an error occurred getting test takers... Please try again',
            'close',
            {
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['bg-danger', 'text-white'],
            }
          );
        },
        complete: () => {
          this.callInProgress = false;
        },
      });
  }
}
