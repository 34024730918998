import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { RequestInProgressComponent } from './request-in-progress/request-in-progress.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    OnboardingComponent,
    RequestInProgressComponent,  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ...SharedModule.getRootModules(),
  ],
  providers: [...SharedModule.getRootProviders()],
  bootstrap: [AppComponent],
})
export class AppModule {}
