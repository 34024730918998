import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appGoBack]',
})
export class GoBackDirective {
  constructor(private readonly location: Location) {}

  @HostListener('click')
  public onClick() {
    this.location.back();
  }
}
