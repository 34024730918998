import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APIBaseURLOption } from '../interfaces/api-base-url-option.interface';
import { API_BASE_URL_TOKEN } from '../providers/api-base-url.provider';
import { IUploadTestTaker } from '../interfaces/upload-test-taker.interface';
import { ITestTaker } from '../interfaces/test-taker.interface';

@Injectable({
  providedIn: 'root',
})
export class TestTakerService {
  public readonly url = `${this.baseUrlProvider.rootBase}/training`;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(API_BASE_URL_TOKEN)
    private readonly baseUrlProvider: APIBaseURLOption
  ) {}

  public getTestTakers(sessionId: string, unitCode: string, limit: number, offset: number) {
    const url = this.url + '/' + sessionId + '/test-taker';
    const paramsObject: any = { limit, offset };

    if (unitCode) {
      paramsObject.unitCode = unitCode;
    }

    return this.httpClient.get<ITestTaker[]>(url, {
      headers: this.headers,
      params: new HttpParams({ fromObject: paramsObject }),
    });
  }

  public submitTestTakers(sessionId: string, data: IUploadTestTaker[]) {
    const url = this.url + '/' + sessionId + '/test-taker/upload';

    return this.httpClient.post<void>(url, data, {
      headers: this.headers,
    });
  }
}
