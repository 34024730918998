import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-in-progress',
  templateUrl: './request-in-progress.component.html',
  styleUrls: ['./request-in-progress.component.scss']
})
export class RequestInProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
