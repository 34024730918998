import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRippleModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { TabbedNavBarComponent } from '../tabbed-nav-bar/tabbed-nav-bar.component';
import { TableComponent } from '../table/table.component';
import { apiBaseURLProvider } from '../providers/api-base-url.provider';
import { environmentsProvider } from '../providers/environment.provider';
import { GoBackDirective } from '../directives/go-back.directive';
import { SearchTestTakersDailogComponent } from '../search-test-takers-dailog/search-test-takers-dailog.component';

const modules = [
  MatButtonModule,
  MatCardModule,
  MatTabsModule,
  MatInputModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatIconModule,
  MatSnackBarModule,
  MatDividerModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatRippleModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatExpansionModule,
  MatInputModule,
  MatBottomSheetModule,
  MatDatepickerModule,
];

@NgModule({
  declarations: [
    TabbedNavBarComponent,
    TableComponent,
    GoBackDirective,
    SearchTestTakersDailogComponent,
  ],
  imports: [CommonModule, RouterModule, ...modules],
  providers: [environmentsProvider, apiBaseURLProvider],
  exports: [
    TabbedNavBarComponent,
    TableComponent,
    RouterModule,
    GoBackDirective,
    ...modules,
  ],
})
export class SharedModule {
  public static getRootProviders() {
    return [
      DatePipe,
      {
        provide: MAT_DATE_FORMATS,
        useValue: {
          parse: { dateInput: 'DD/MM/YYYY' },
          display: {
            dateInput: 'DD/MM/YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
          },
        },
      },
    ];
  }

  public static getRootModules() {
    return [BrowserAnimationsModule, MatMomentDateModule, HttpClientModule];
  }
}
