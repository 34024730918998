<div class="d-flex flex-column rounded-3 recharge--dialog__container">
    <!-- INFO toolbar shown on small devices -->
    <mat-toolbar class="d-flex d-sm-none bg-white shadow-sm py-1">
        <button class="my-auto rounded-3" mat-icon-button color="primary" mat-dialog-close>
            <mat-icon class="m-auto" aria-hidden="false" aria-label="search">arrow_back</mat-icon>
        </button>

        <ng-container *ngTemplateOutlet="previewHeaderText"></ng-container>
    </mat-toolbar>

    <!-- INFO Header text Template -->
    <ng-template #previewHeaderText>
        <span class="ms-2 ms-sm-0 mat-h3 my-auto fw-bold text-dark">Find my details</span>
    </ng-template>

    <!-- INFO Header shown on Large devices -->
    <div class="d-none d-sm-flex w-100 p-sm-4">
        <ng-container *ngTemplateOutlet="previewHeaderText"></ng-container>
    </div>

    <!-- INFO divider only for large devices -->
    <mat-divider class="d-none d-sm-block"></mat-divider>

    <div class="d-flex flex-column h-100 px-2 px-sm-3 py-3 pb-sm-3" style="overflow-x: hidden; overflow-y: auto;">
        <!-- INFO Form Fields -->
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Name or Phone Number</mat-label>
            <input matInput type="text" [formControl]="searchControl" placeholder="your name or phone number">
        </mat-form-field>

        <div *ngIf="testTakers.length > 0" class="d-flex flex-column w-100">
            <mat-card *ngFor="let testTaker of testTakers$ | async; index as i;" class="d-flex my-2 border rounded-3 shadow-none">
                <span class="me-2">{{ testTaker.name }} ({{ testTaker.phoneNumber }}) - {{ testTaker.tag }}</span>
                <button mat-flat-button class="ms-auto rounded-3" color="primary" (click)="selectTestTaker(i)">
                    <span class="mat-caption">Select</span>
                </button>
            </mat-card>
        </div>

        <!-- <button mat-button class="mx-auto rounded-3" color="primary" style="height: 36px;" (click)="findTestTakers()">Load more...</button> -->
    </div>

    <!-- INFO divider only for large devices -->
    <mat-divider class="d-none d-sm-flex"></mat-divider>

    <!-- INFO bottom buttons only for large devices -->
    <div class="d-none d-sm-flex p-2 w-100">
        <button mat-button mat-dialog-close class="ms-auto rounded-3" color="warn" style="height: 36px;">Close</button>
    </div>
</div>
