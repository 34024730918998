<div class="rounded-3" style="overflow: auto;">
    <table mat-table class="w-100 h-100" [dataSource]="dataSource">
        <!-- INFO Dynamic Column -->
        <div *ngFor="let header of displayedColumns" [matColumnDef]="header.toLocaleLowerCase()">
            <th mat-header-cell class="px-4 text-capitalize" *matHeaderCellDef>
                <span class="fw-bolder">{{ header }}</span>
            </th>

            <ng-container *matCellDef="let element">
                <td mat-cell *ngIf="isBooleanPrimitive(element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header])" class="px-4"> {{
                    booleanTransformTuple[
                    element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header] ? 0
                    : 1 ] || '-' }} </td>
                <!-- </ng-container> -->

                <td mat-cell *ngIf="isDate(element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header]) && dateKeys.includes(headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header)"
                    class="px-4"> {{ element[headerMap && headerMap[header.toLowerCase()] ?
                    headerMap[header.toLowerCase()] : header.toLocaleLowerCase()] |
                    date:(dateFormatMap && dateFormatMap[header.toLowerCase()] ? dateFormatMap[header.toLowerCase()] : 'mediumDate') || '-' }} </td>

                <!-- INFO show any other except these things -->
                <!-- <ng-container *matCellDef="let element"> -->
                <td mat-cell
                    *ngIf="isNonBooleanPrimitive(element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header]) && !dateKeys.includes(headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header)"
                    class="px-4"> {{ element[headerMap && headerMap[header.toLowerCase()] ?
                    headerMap[header.toLowerCase()] : header] || '-' }} </td>

                <td mat-cell *ngIf="isObject(element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header])" class="px-4"> {{
                    traverseObjectData(header.toLowerCase(),
                    element[headerMap && headerMap[header.toLowerCase()] ? headerMap[header.toLowerCase()] : header]) || '-' }}
                </td>
            </ng-container>

            <!-- <ng-container *ngIf="objectKeys.includes(header.toLocaleLowerCase())">
                <td mat-cell *matCellDef="let element" class="px-4"> {{ traverseObjectData(header.toLowerCase(), element[header.toLocaleLowerCase()]) }} </td>
            </ng-container> -->

            <!-- <ng-container *ngIf="header === 'time'">
                <td mat-cell *matCellDef="let element" class="px-4"> {{element[header.toLocaleLowerCase()] |
                    date:'mediumDate'}} </td>
            </ng-container> -->

            <ng-template #tagButton>
                <td mat-cell class="pe-3" *matCellDef="let element">
                    <mat-icon class="m-auto material-icons-outlined" [inline]="true" color="warn">tag_outline
                    </mat-icon>
                </td>
            </ng-template>
        </div>

        <tr mat-header-row class="py-3 rounded-3" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClickHandler(row, $event)"></tr>
    </table>
</div>

<mat-paginator class="w-100 mt-2" [class]="items && items.length <= 0 ? 'd-none' : null"
    [pageSizeOptions]="pageSizeOptions" [length]="items.length" hidePageSize pageSize="pageSize"
    [ngStyle]="{minWidth: 'fit-content'}" (page)="pageChange($event)">
</mat-paginator>
