import { Component, Input, OnInit } from '@angular/core';
import { NavTabItem } from '../onboarding/onboarding.component';

@Component({
  selector: 'app-tabbed-nav-bar',
  templateUrl: './tabbed-nav-bar.component.html',
  styleUrls: ['./tabbed-nav-bar.component.scss'],
})
export class TabbedNavBarComponent implements OnInit {
  @Input() public links: NavTabItem[] = [];
  public activeLink: string = this.links[0]?.path;

  constructor() {}

  ngOnInit(): void {}
}
