// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'test-auth-a1971',
    appId: '1:102827640491:web:ff3a8416dc0f372d5359ee',
    storageBucket: 'test-auth-a1971.appspot.com',
    apiKey: 'AIzaSyBD6m7wWy-xJBZDrtG8Ab4WWtiC5DWOKxc',
    authDomain: 'test-auth-a1971.firebaseapp.com',
    messagingSenderId: '102827640491',
    measurementId: 'G-SNEXXN8CHF',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
