<div class="d-flex w-100 bg-white recharge--onboarding__container">
    <mat-card class="m-auto p-3 shadow-none">
        <!-- INFO header -->
        <div class="d-flex w-100 py-3">
            <img class="me-1" src="/assets/images/commission-logo.png" alt="LFCWWI Image" loading="lazy"
                [ngStyle]="{ width: '72px', height: '72px' }">

            <!-- INFO Text -->
            <div class="d-flex flex-column ms-3 my-auto">
                <span class="mb-0 mat-display-1 fw-bold">Recharge</span>
                <span class="mat-caption">...he shall renew their strength...</span>
            </div>
        </div>

        <!-- INFO Tab link -->
        <div class="d-flex flex-column mt-3 py-3">
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
                <a mat-tab-link *ngFor="let link of links" [class]="activeLink === link.path ? 'border border-primary' : 'text-dark'" class="py-2" (click)="activeLink = link.path"  [active]="activeLink == link.path"
                    [routerLink]="[link.path]" routerLinkActive (isActiveChange)="$event ? activeLink = link.path : link">
                    <span class="my-auto mat-caption">{{
                    link.title }}</span>
                </a>
            </nav>

            <!-- INFO Router Outlet for Tab Content -->
            <mat-tab-nav-panel #tabPanel>
                <div class="py-4">
                    <router-outlet></router-outlet>
                </div>
            </mat-tab-nav-panel>
        </div>
    </mat-card>
</div>
