import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TrieService {
  constructor() {}

  public prefixSearchVariant<T = any>(word: string, searchCollection: T[]) {
    let result: T[] = [];
    const collection = searchCollection.sort() as any[];

    let t = 0;
    let b = collection.length - 1;

    for (let i = 0; i < word.split('').length; i++) {
      const chunk = word.split('')[i].toLowerCase();

      while (
        t <= b &&
        (collection[t].length <= i || collection[t][i].toLowerCase() !== chunk)
      ) {
        t += 1;
      }

      while (
        t <= b &&
        (collection[b].length <= i || collection[b][i].toLowerCase() !== chunk)
      ) {
        b -= 1;
      }

      if (t <= b) result = collection.slice(t, b + 1);
    }

    return result;
  }
}
