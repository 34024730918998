
import { environment } from 'src/environments/environment';
import { ENVIRONMENTS } from '../constants/provider.constant';

export const environmentsProvider = {
  provide: ENVIRONMENTS,
  useFactory: (): { production: boolean } => {
    return environment;
  },
  deps: [],
};
