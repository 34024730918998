<div class="d-flex flex-column w-100 recharge--board-settings__container">
    <nav mat-tab-nav-bar class="position-sticky bg-white" [tabPanel]="tabPanel" [ngStyle]="{ top: 0, zIndex: 5 }">
        <a mat-tab-link #tab *ngFor="let link of links" [class]="activeLink === link.path ? 'text-dark' : 'text-dark'"
            class="d-flex flex-column pt-2" (click)="activeLink = link.path" [active]="activeLink == link.path"
            [routerLink]="[link.path]" routerLinkActive (isActiveChange)="$event ? activeLink = link.path : link"
            [ngStyle]="{minWidth: 'fit-content'}">
            <span class="my-auto mat-caption">{{
                link.title }}</span>
            <div *ngIf="activeLink == link.path" class="mx-auto mt-auto px-3 pt-1 bg-primary rounded-top"></div>
        </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
        <!-- INFO in app navigation -->
        <!-- <div class="d-flex w-100">
            <button mat-button class="my-auto p-3 rounded-3" color="primary">
                <mat-icon>arrow_backward</mat-icon>
                <span class="ms-2 my-auto mat-caption">Back</span>
            </button>
        </div> -->

        <div class="d-flex flex-column py-4">
            <router-outlet></router-outlet>
        </div>
    </mat-tab-nav-panel>
</div>
