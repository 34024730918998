import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SHA1 } from 'crypto-js';
import { API_PAGE_LIMIT } from '../constants/api.constant';

export interface TableRowEvent<D> {
  event: MouseEvent;
  data: D;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input('displayedColumns') public displayedColumns!: string[];
  @Input('items') public items: any[] = [];
  public dataSource!: MatTableDataSource<any>;
  @ViewChild(MatTable) public table!: MatTable<any>;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public lastHash!: string;
  @Output() public rowClicked: EventEmitter<TableRowEvent<any>> =
    new EventEmitter<TableRowEvent<any>>();
  @Output() public endOfTable: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(MatPaginator) public matPaginator!: MatPaginator;
  @Input('objectKeys') public objectKeys: string[] = [];
  @Input('objectKeyPathMap') public objectKeyPathMap: {
    [key in string]: string;
  } = {};
  @Input('dateKeys') public dateKeys: string[] = [];
  @Input('booleanTransformTuple') public booleanTransformTuple: string[] = [
    'Enabled',
    'Disabled',
  ];
  @Input() public pageSizeOptions: number[] | readonly number[] = Array.from(
    { length: 5 },
    (_, i) => (i + 1) * 10
  );
  @Input() public headerMap?: { [key: string]: string };
  @Input() public dateFormatMap?: { [key: string]: string };
  public pageSize: number = API_PAGE_LIMIT / 2;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initPaginator();
  }

  ngDoCheck(): void {
    const hash = SHA1(JSON.stringify(this.items)).toString();

    if (this.lastHash != hash) {
      this.lastHash = hash;
      this.initPaginator();
    }
  }

  public isNonBooleanPrimitive(data: any) {
    return ['string', 'number'].includes(typeof data);
  }

  public isBooleanPrimitive(data: any) {
    return typeof data === 'boolean';
  }

  public isDate(data: any) {
    return new Date(data).toString().toLowerCase() !== 'invalid date';
  }

  public isObject(data: any) {
    return typeof data === 'object';
  }

  // INFO CAN ALSO USE RECURSION
  public traverseObjectData(rootKey: string, data: any) {
    if (!this.objectKeyPathMap[rootKey]) return;

    const path = this.objectKeyPathMap[rootKey].split('.');
    let curData = {};

    for (const element of path) {
      const cur = element;

      curData = data[cur];
    }

    return curData;
  }

  public pageChange(d: PageEvent) {
    const hasNextPage = this.matPaginator.hasNextPage();

    if (!hasNextPage) {
      this.endOfTable.emit(true);
    }
  }

  public rowClickHandler(row: any, e: MouseEvent) {
    this.rowClicked.emit({ event: e, data: row });
  }

  private initPaginator(): void {
    if (this.items?.length) {
      this.dataSource = new MatTableDataSource([...this.items]);
      // this.paginator.pageSize = this.pageSize;
      this.dataSource.paginator = this.paginator;
    }
  }
}
